@import "../../styles/variables";
.table {
  margin-bottom: 20px;
  thead tr th {
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #544f5a;
    background-color: #f5f5f9 !important;
  }
  thead tr th:not(:first-child) {
    font-size: 0.75rem;
    vertical-align: middle;
  }
}
.item_row {
  vertical-align: baseline;
  td {
    font-size: 14px;
  }
}
.header_table_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dee2e6;
  .header_table {
    min-height: 500px;
    font-size: 2rem;
    font-weight: 600;
    padding: 20px;
  }
  .app_search {
    padding: calc(47px / 2) 0;
    input {
      height: 40px;
      padding-left: 40px;
      padding-right: 20px;
    }
    svg {
      position: absolute;
      font-size: 20px;
      left: 13px;
      top: 10px;
      color: #959396;
    }
  }
}

.header_table_container,
.header_table {
  box-shadow: 0 0.375rem 1rem 0 rgba(58, 53, 65, 0.12);
}

.header_table {
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  padding-bottom: 10px;
}
.header_thead {
  background-color: #696cff;
  color: #fff;
}
.custom-thead {
  background-color: #696cff;
  color: #ffffff;
}
.header_add_button {
  padding: 5px 10px;
  border: none;
  box-shadow: $box-shadow-1;
  background-color: rgb(232, 247, 255);;
  border-radius: 8px;
  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    span,
    svg {
      font-weight: 600;
      margin-right: 4px;
      font-size: 15px;
      color: $primary;
    }
  }
}
.item_row {
  vertical-align: baseline;
}
.btn_action_group {
  .item_action {
    &:hover {
      background-color: #e9e8e8 !important;
    }
    svg {
      font-size: 1.5rem;
    }
  }
}

.thumbnail_item {
  max-width: 80px;
  height: 60px;
}
.header_delete_button {
  padding: 5px 10px;
  margin: 20px;
  border: none;
  box-shadow: $box-shadow-1;
  background-color: #ffeded;
  border-radius: 8px;
  transition: all 100ms ease-in-out;
  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    span,
    svg {
      font-weight: 600;
      margin-right: 4px;
      font-size: 15px;
      color: $secondary;
    }
  }
  &.inactive {
    background-color: #ffffff;
    opacity: 0.5;
    pointer-events: none;
  }
}

.btn_group {
  vertical-align: baseline !important;
  .btn_filter {
    padding: 5px 10px;
    margin: 20px;
    border: none;
    box-shadow: $box-shadow-1;
    border-radius: 8px;
    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
      span,
      svg {
        font-weight: 600;
        margin-right: 4px;
        font-size: 15px;
        color: $secondary;
      }
    }
  }
}
.image_cate {
  margin-top: 20px;
}
.btn_primary {
  background: #2886b8  !important;
  border: 1px solid #2886b8  !important;
}
.pagination_container {
  display: flex;
}
.page_select {
  margin-right: 40px;
}
.Pagination_div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .pagination {
    li {
      margin-right: 0.2rem;
      margin-left: 0.2rem;
    }
  }
  .paginationInfo {
    color: #b4b2b7;
    font-weight: 300;
  }
}
