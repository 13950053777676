.ProfileInnner {
  .editedProfile button {
    background-color: #2886b8;
    width: 100% !important;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
  }
}
.buttonUpdate button{
  background-color: #2886b8;
  width: 100% !important;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
}
