@import "../../../styles/variables";
.vertical_menu.collapsed_menu {
  width: 80px;
  .navbar_brand_box {
    width: 70px;
    .logo_text {
      display: none;
    }
  }
  button {
    display: none;
  }
  .sidebar_menu_scroll .sidebar_menu {
    position: absolute;
    left: 0;
    right: 10px;
    z-index: 9999;
    .menu_item {
      width: 100%;
      display: none;
    }
    li {
      a {
        width: 100%;
        svg {
          font-size: 1.5rem;
        }
      }
    }
  }
}
.vertical_menu {
  width: 255px;
  z-index: 1005;
  bottom: 0;
  margin-top: 0;
  position: fixed;
  top: 0;
  border-right: 1px solid var(--bs-sidebar-border-color);
  box-shadow: 0 2px 3px rgba(52, 58, 64, 0.06);
  .navbar_brand_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 0 1.5rem;
    width: 255px;
    position: fixed;
    z-index: 1;
    .logo_dark {
      line-height: 85px;
      text-decoration: none;
      .logo_text {
        font-weight: 700;
        font-size: 20px;
        vertical-align: text-bottom;
        margin-left: 5px;
        line-height: 1;
        color: black !important;
      }
    }
    button.vertical_collapsed {
      border: none !important;
    }
    button svg {
      font-size: 24px !important;
      color: var(--bs-header-item-color);
    }
  }
  .sidebar_menu_scroll {
    height: calc(100% - 85px);
    margin-top: 85px;
    overflow-y: auto;
    .sidebar_menu {
      padding: 0 0 30px 0;
      li {
        margin-bottom: 0.8rem;
        a {
          display: flex;
          align-items: center;
          color: gray;
          text-decoration: none;
          padding: 0.65rem 1rem;
          position: relative;
          font-weight: 500;
          margin: 0 10px;
          border-radius: 3px;
          font-size: 14.4px;
          align-items: center;
          &:hover {
            color: #2886b8;
          }
          svg {
            margin-right: 10px;
            font-size: 18px;
          }
        }
      }
      .active_link {
        background-color: rgb(232, 247, 255);
        color: $primary;
        &::before {
          content: "";
          height: 100%;
          top: 0;
          width: 3px;
          background-color: $primary;
          position: absolute;
          left: 0;
          border-end-start-radius: 3px;
          border-start-start-radius: 3px;
        }
      }
    }
    &::-webkit-scrollbar {
      background-color: transparent;
      position: absolute;
      right: 2px;
      width: 6px;
      min-height: 10px;
    }
    &::-webkit-scrollbar-thumb {
      position: absolute;
      height: 379px;
      content: "";
      background: #a2adb7;
      border-radius: 7px;
      left: 0;
      right: 0;
      opacity: 0;
      background-clip: content-box;
      transition: opacity 0.2s linear;
    }
  }
}

@media screen and (max-width: 994px) {
  .vertical_menu {
    display: none;
  }
}
