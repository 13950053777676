:root {
  --bs-sidebar-bg: #ffffff;
  --bs-sidebar-border-color: #e9ebef;
  --bs-sidebar-menu-item-color: #7b8190;
  --bs-sidebar-menu-sub-item-color: #7b8190;
  --bs-sidebar-menu-item-icon-color: #7b8190;
  --bs-sidebar-menu-item-hover-color: #383c40;
  --bs-sidebar-menu-item-active-color: #776acf;
  --bs-sidebar-menu-sub-item-active-color: #383c40;
  --bs-sidebar-menu-item-active-bg-color: #eeedf9;
  --bs-sidebar-dark-bg: #24243c;
  --bs-sidebar-dark-menu-item-color: #9292a9;
  --bs-sidebar-dark-menu-sub-item-color: #9292a9;
  --bs-sidebar-dark-menu-item-icon-color: #9292a9;
  --bs-sidebar-dark-menu-item-hover-color: #d8d8df;
  --bs-sidebar-dark-menu-item-active-color: #d8d8df;
  --bs-sidebar-dark-menu-item-active-bg-color: #2c2c45;
  --bs-header-bg: #ffffff;
  --bs-header-item-color: #555b6d;
  --bs-header-dark-bg: radial-gradient(at 50% -20%, #3f1e59, #311748) fixed;
  --bs-header-dark-item-color: #e9ecef;
  --bs-topbar-search-bg: #f8f7fc;
  --bs-footer-bg: #ffffff;
  --bs-footer-color: #74788d;
  --bs-topnav-bg: #fff;
  --bs-menu-item-color: #7b8190;
  --bs-menu-item-active-color: #776acf;
  --bs-topnav-dark-bg: #03314d;
  --bs-menu-dark-item-color: rgba(255, 255, 255, 0.5);
  --bs-menu-item-active-border-color: #776acf;
  --bs-menu-dark-item-hover-color: #ffffff;
  --bs-menu-dark-item-active-color: #fff;
  --bs-navbar-brand-box-bg: #ffffff;
  --bs-boxed-body-bg: #e9e5eb;
  --bs-apex-grid-color: #f8f9fa;
  --bs-body-bg: #f9f6fa;
  --bs-body-color: #1a1823;
  --bs-body-color-rgb: 26, 24, 35;
  --bs-display-none: none;
  --bs-display-block: block;
  --bs-light: #f8f7fc;
  --bs-light-rgb: 248, 247, 252;
  --bs-dark: #212529;
  --bs-dark-rgb: 33, 37, 41;
  --bs-text-muted: #959396;
  --bs-link-color: #776acf;
  --bs-link-hover-color: #776acf;
  --bs-border-color: #eff0f2;
  --bs-dropdown-bg: #fff;
  --bs-dropdown-link-color: #212529;
  --bs-dropdown-link-hover-color: #1f2327;
  --bs-dropdown-link-hover-bg: #f8f9fa;
  --bs-dropdown-border-width: 0px;
  --bs-card-bg: #fff;
  --bs-card-cap-bg: #fff;
  --bs-card-border-color: #eff0f2;
  --bs-card-logo-dark: block;
  --bs-card-logo-light: none;
  --bs-modal-bg: #fff;
  --bs-modal-content-bg: #fff;
  --bs-modal-content-border-color: #eff0f2;
  --bs-nav-tabs-link-active-color: #1a1823;
  --bs-nav-tabs-link-active-bg: #f9f6fa;
  --bs-accordion-button-active-color: #6b5fba;
  --bs-accordion-bg: #fff;
  --bs-accordion-button-bg: #f4f5f6;
  --bs-table-color: #1a1823;
  --bs-progress-bg: #f4f5f6;
  --bs-toast-background-color: rgba(255, 255, 255, 0.85);
  --bs-toast-border-color: rgba(0, 0, 0, 0.1);
  --bs-toast-header-border-color: rgba(0, 0, 0, 0.05);
  --bs-list-group-hover-bg: #f8f9fa;
  --bs-popover-bg: #fff;
  --bs-pagination-hover-bg: #f4f5f6;
  --bs-input-bg: #fff;
  --bs-input-group-addon-border-color: #e2e5e8;
  --bs-input-border: #e2e5e8;
  --bs-input-border-color: #e2e5e8;
  --bs-input-focus-border: #bbb5e7;
  --bs-input-disabled-bg: #f4f5f6;
  --bs-table-head-bg: #f8f9fa;
  --bs-input-placeholder-color: #959396;
  --bs-input-group-addon-bg: #f4f5f6;
  --bs-input-check-border: var(--bs-input-border);
}
[data-layout-mode="dark"] {
  --bs-gray-100: #212529;
  --bs-gray-200: #2a2a42;
  --bs-gray-300: #2d2d49;
  --bs-gray-400: #9295a4;
  --bs-gray-500: #858592;
  --bs-gray-600: #c3cbe4;
  --bs-gray-700: #d8d8df;
  --bs-gray-800: #eff2f7;
  --bs-gray-900: #f8f9fa;
  --bs-sidebar-bg: #ffffff;
  --bs-sidebar-border-color: #e9ebef;
  --bs-sidebar-menu-item-color: #7b8190;
  --bs-sidebar-menu-sub-item-color: #7b8190;
  --bs-sidebar-menu-item-icon-color: #7b8190;
  --bs-sidebar-menu-item-hover-color: #383c40;
  --bs-sidebar-menu-item-active-color: #776acf;
  --bs-sidebar-menu-sub-item-active-color: #383c40;
  --bs-sidebar-menu-item-active-bg-color: #eeedf9;
  --bs-sidebar-dark-bg: #24243c;
  --bs-sidebar-dark-menu-item-color: #9292a9;
  --bs-sidebar-dark-menu-sub-item-color: #9292a9;
  --bs-sidebar-dark-menu-item-icon-color: #9292a9;
  --bs-sidebar-dark-menu-item-hover-color: #d8d8df;
  --bs-sidebar-dark-menu-item-active-color: #d8d8df;
  --bs-sidebar-dark-menu-item-active-bg-color: #2c2c45;
  --bs-header-bg: #ffffff;
  --bs-header-item-color: #555b6d;
  --bs-header-dark-bg: radial-gradient(at 50% -20%, #24243c, #222236) fixed;
  --bs-header-dark-item-color: #e9ecef;
  --bs-topbar-search-bg: #f8f7fc;
  --bs-footer-bg: #24243c;
  --bs-footer-color: #d8d8df;
  --bs-menu-item-color: #9292a3;
  --bs-menu-item-active-color: #776acf;
  --bs-topnav-dark-bg: #03314d;
  --bs-menu-dark-item-color: rgba(255, 255, 255, 0.5);
  --bs-menu-item-active-border-color: #776acf;
  --bs-menu-dark-item-hover-color: #ffffff;
  --bs-menu-dark-item-active-color: #fff;
  --bs-navbar-brand-box-bg: #ffffff;
  --bs-apex-grid-color: #32394e;
  --bs-body-bg: #1b1c31;
  --bs-body-color: #9295a4;
  --bs-body-color-rgb: 26, 24, 35;
  --bs-display-none: block;
  --bs-display-block: none;
  --bs-card-title-desc: #9295a4;
  --bs-topnav-bg: #2e2e48;
  --bs-topnav-item-color: #9295a4;
  --bs-topnav-item-color-active: #fff;
  --bs-light: #2d2d49;
  --bs-light-rgb: 45, 45, 73;
  --bs-dark: #eff2f7;
  --bs-dark-rgb: 239, 242, 247;
  --bs-text-muted: #858592;
  --bs-link-color: #d8d8df;
  --bs-link-hover-color: #d8d8df;
  --bs-border-color: #2d2d49;
  --bs-dropdown-bg: #2a2a42;
  --bs-dropdown-link-color: #858592;
  --bs-dropdown-link-hover-color: #ecedee;
  --bs-dropdown-link-hover-bg: #2d2d49;
  --bs-dropdown-border-width: 1px;
  --bs-card-bg: #24243c;
  --bs-card-cap-bg: #24243c;
  --bs-card-border-color: #2b2b46;
  --bs-card-logo-dark: none;
  --bs-card-logo-light: block;
  --bs-modal-bg: #2a2a42;
  --bs-modal-content-bg: #2a2a42;
  --bs-modal-content-border-color: #2d2d49;
  --bs-nav-tabs-link-active-color: #f8f9fa;
  --bs-nav-tabs-link-active-bg: #2d2d49;
  --bs-accordion-button-active-color: #fff;
  --bs-accordion-bg: transparent;
  --bs-accordion-button-bg: #2d2d49;
  --bs-table-color: #9295a4;
  --bs-boxed-body-bg: #14142b;
  --bs-progress-bg: #2d2d49;
  --bs-toast-background-color: rgba(45, 45, 73, 0.85);
  --bs-toast-border-color: rgba(255, 255, 255, 0.1);
  --bs-toast-header-border-color: rgba(255, 255, 255, 0.05);
  --bs-list-group-hover-bg: #2f2f4a;
  --bs-popover-bg: #2c2c45;
  --bs-pagination-hover-bg: #32324e;
  --bs-input-bg: #2e2e48;
  --bs-input-group-addon-border-color: #2d2d49;
  --bs-input-border: #363654;
  --bs-input-border-color: #2d2d49;
  --bs-input-focus-border: #353556;
  --bs-input-disabled-bg: #2a2a42;
  --bs-table-head-bg: #2d2d49;
  --bs-input-placeholder-color: #9295a4;
  --bs-input-group-addon-bg: #363652;
  --bs-input-check-border: #353556;
}

[data-simplebar] {
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-box-pack: start;
  -webkit-box-align: start;
}

// Color
$black: #333f48;
$primary: #2886b8;
$hover_primary: #996414;
$secondary: #c91e03;
$success: #008f44;
$danger: #e45d15;
// Btn-Padding
$btn-gray: #e1e1e1;
$bg-footer: #1a1a1a;

$btn-padding-x: 2rem;
$btn-padding-y: 0.475rem;

// Box shadow
$box-shadow: 0 0.5rem 1rem rgba($black, 0.15) !default;
$box-shadow-1: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
  rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
// Border Radius
$borderRadius: 5px;
