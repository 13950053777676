.container {
  .row {
    .revenueContainer,
    .saleFeeSpendContainer,
    .ros,
    .revenuePieChart,
    .revenueDirectSale,
    .revenueMonthCTVSale,
    .growthamonth,
    .revenueMonthTotal,
    .eventDiscount {
      border-radius: 8px;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
      padding: 20px;
      margin: 10px;
      background: #fff;
      h3 {
        position: relative;
        font-weight: 600;
        padding-bottom: 0.5rem;
        &::after {
          content: "";
          width: 60px;
          height: 1.5px;
          background: gray;
          position: absolute;
          left: 0;
          bottom: 3px;
        }
      }
      p {
        font-weight: 500;
        font-size: 18px;
      }
    }
    .row_title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        svg {
          font-size: 24px;
        }
      }
    }
    h3 {
      font-size: 15px;
    }
  }
}

@media screen {
}
